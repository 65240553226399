import HTTP from "@/helpers/http-common"
import EventBus from "@/eventBus"

export default {
  async fetchConfig({ dispatch, commit }, { payload }) {
    return HTTP.get("api/v1/appConfig/" + payload.key).then((response) => {
      console.log(response.data)
      if (response.data) {
        commit("SET_CONFIG", response.data.data)
      }
    })
  },
  async fetchOptions({ commit }) {
    return HTTP.get("api/v1/statuses").then((response) => {
      console.log(response.data)
      if (response.data) {
        commit("SET_OPTIONS", response.data.data)
      }
    })
  },
	async updateZoneToggleSetting({state, commit}, {payload}){

		return new Promise((resolve, reject) => {
			var zoneToggleSetting = payload.zoneToggleSetting
			HTTP.post("/api/v1/profile/zone_toggle_setting",
				{
					zone_toggle_setting: payload.zoneToggleSetting
				}).then((response) => {
					//set the value in state
					commit("SET_ZONE_TOGGLE_SETTING", zoneToggleSetting)
					resolve('success')
				}).catch((error) => {
					reject('error')
				})
		})
	},
  showMessage({ commit }, payload) {
    console.log("Showing message")
    commit("SHOW_MESSAGE", payload)
  },
  zoomIn({ dispatch, commit }) {
    commit("ZOOM_IN")
  },
  zoomOut({ dispatch, commit }) {
    commit("ZOOM_OUT")
  },
  socketConnected({ commit }, payload) {
    console.log("Socket is now connected", payload.info)
    commit("SOCKET_CONNECTED", payload.info)
  },
  socketDisconnected({ commit }, payload) {
    console.log("Socket is now disconnected", payload.context)
    commit("SOCKET_DISCONNECTED", payload.context)
  },
  socketCheckConnection({ commit }) {
    commit("SOCKET_CHECK_CONNECTION", true)
  },
  uploadFile({ dispatch, commit }, payload) {
    console.log('Uploading =>', payload)
  },
  processWSMessage({ dispatch, commit }, payload) {
    // console.log("Message received", payload.msg)

    const message = payload.msg

    if (message.data !== undefined) {
      if (message.data.data_type !== undefined) {
        switch (message.data.data_type) {
          case "TotalPages":
            EventBus.$emit("import/totalpages", message)
            break
          case "currentpage":
            EventBus.$emit("import/currentpage", message)
            break
          case "UploadCount":
            EventBus.$emit("import/uploadcount", message)
            break
          case "OCRCount":
            EventBus.$emit("import/OCRCount", message)
            break
          case "OCRCell":
            EventBus.$emit("import/OCRCell", message)
            break
          case "message":
            EventBus.$emit("import/message", message)
            break
          case "error":
            EventBus.$emit("import/error", message)
            break
          case "htmlout":
            EventBus.$emit("htmlout/progress", message)
            break
          case "htmlerror":
            EventBus.$emit("htmlout/error", message)
            break
          case "pdfout":
            EventBus.$emit("pdfout/progress", message)
            break
          case "pdfout_error":
          case "pdfout_warning":
            EventBus.$emit("pdfout/error", message)
            break
          case "html_ready":
            EventBus.$emit("html_ready", message)
            break
          case "pdf_ready":
            EventBus.$emit("pdf_ready", message)
            break
          case "epubout":
            EventBus.$emit("epubout/progress", message)
            break
          case "epub_ready":
            EventBus.$emit("epub_ready", message)
            break
          case "notification":
            EventBus.$emit("notification", message)
            dispatch('user/fetchNotifications', null, { root: true })
            break
          case "callback":
            // console.log("Callback")
            if (
              message.data !== undefined &&
              message.data.cb_type != undefined
            ) {
              EventBus.$emit("callback-" + message.data.cb_type, message)
            } else {
              EventBus.$emit("callback", message)
            }
            break
        }
      } else {
        EventBus.$emit("channel/message", message)
      }
    } else {
      EventBus.$emit("channel/message", message)
    }
  },
}
