<template>

<v-container class="ma-2 pa-2">
    <v-row>
        <v-col cols="12" >

            <v-card class="pa-4"
                elevation="2"
            >
                <v-row>
                    <v-col cols="2">
                        <v-hover v-slot="{ hover }" :key="document.doc_key">
                        <v-card
                            :elevation="hover ? 12 : 2"
                            :class="{ 'on-hover': hover }"
                        >
													<a @click="editDocument">
														<v-img 
															:src="'/api/v1/thumbnail/' + document.thumbnail_url"></v-img>
													</a>
                        </v-card>
                        </v-hover>
                    </v-col>

                <v-col cols="10">
                    <v-row>
                    <v-col class="text-body-1 primary--text font-weight-bold">
                        <h3 class="h3-title">{{ document.name }}</h3>
                    </v-col>
                    </v-row>

                    <v-row>
                    <v-col v-if="language == 'en'">
                        <vue-moments-ago
                        prefix="Imported"
                        suffix="ago"
                        :date="document.imported_on"
                        lang="en"
                        :key="componentKey"
                        ></vue-moments-ago>
                    </v-col>
                    <v-col v-if="language == 'fr'">
                        <vue-moments-ago
                        prefix="Importé il y a"
                        :date="document.imported_on"
                        suffix=""
                        lang="fr"
                        :key="componentKey"
                        ></vue-moments-ago>
                    </v-col>
                    </v-row>

                    <v-row>
                    <v-col>
                        {{ document.title }}
                    </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            {{ document.imported_by_name }}
                        </v-col>
                    </v-row>

                    <ValidatedProgress v-bind:document="document" />

                    <v-row>
                        <v-col>
                            <LabelsDropdown :document="document" />
                        </v-col>
                        <v-col  class="mt-5">
													<v-btn color="primary" v-bind="buttonStyle.buttons" class="buttonStyle.ideal == false ? float-right ml-4 : float-right" @click="zoneTransfer" 
														v-if="options.zone_transfer_enabled">
														<v-icon v-if="buttonStyle.icons.display"  v-bind:class="buttonStyle.icons.class">mdi-content-copy</v-icon>
														<span v-if="buttonStyle.labels.display">{{ $t("documents.zoneTransfer") }}</span>
													</v-btn>

													<v-btn class="buttonStyle.ideal == false ? float-right ml-4 : float-right" v-bind="buttonStyle.buttons" color="error" @click="deleteItem" >
														<v-icon v-if="buttonStyle.icons.display" v-bind:class="buttonStyle.icons.class">mdi-trash-can</v-icon>
														<span v-if="buttonStyle.labels.display">{{ $t("document.delete") }}</span>
													</v-btn>

													<!-- Delete dialog -->
													<v-dialog v-model="dialogDelete" max-width="550px">
														<v-card v-if="!options.enable_permanent_delete">
															<v-card-title class="text-h5">
																{{ $t("documents.headerConfirmDelete") + " " + document.name + "?" }}
															</v-card-title>
															<v-card-actions>
																<v-spacer></v-spacer>
																<v-btn color="blue darken-1" text @click="closeDelete">
																	{{ $t("documents.buttonCancel") }}
																</v-btn>
																<v-btn color="blue darken-1" text @click="deleteItemConfirm">
																	{{ $t("documents.buttonOk") }}
																</v-btn>
															</v-card-actions>
														</v-card>
														<!-- Permanent or archive version -->
														<v-card v-if="options.enable_permanent_delete">
															<v-card-title class="text-h5">
																{{ $t("documents.headerArchiveOrPermanentDelete") + " " + document.name + "?" }}
															</v-card-title>
															<v-card-actions>
																<v-spacer></v-spacer>
																<v-btn color="blue darken-1" text @click="closeDelete">
																	{{ $t("documents.buttonCancel") }}
																</v-btn>
																<v-btn color="blue darken-1" text @click="deleteItemConfirm">
																	{{ $t("documents.buttonArchive") }}
																</v-btn>
																<v-btn color="blue darken-1" text @click="permanentDeleteItemConfirm">
																	{{ $t("documents.buttonPermanentDelete") }}
																</v-btn>
															</v-card-actions>
														</v-card>
													</v-dialog>
													<!-- btn  plain,outlined, text-->
													<v-btn class="float-right" v-bind="buttonStyle.buttons" color="primary" link :to="'/document/' + document.key">
														<v-icon v-if="buttonStyle.icons.display" v-bind:class="buttonStyle.icons.class">mdi-pencil</v-icon>
														<span v-if="buttonStyle.labels.display">{{ $t("documents.edit") }}</span>
													</v-btn>					
                        </v-col>

                    </v-row>
                </v-col>
                </v-row>
                <!-- 
                <v-skeleton-loader
                    class="mx-auto"
                    max-width="300"
                    type="card"
                    ></v-skeleton-loader>
                    -->
            </v-card>

        </v-col>
      </v-row>
      
    </v-container>        
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import VueMomentsAgo from "@/components/MomentsAgo"
import ValidatedProgress from "@/components/ValidatedProgress"
import LabelsDropdown from "@/components/LabelsDropdown"
import router from "../../../router"

export default {
  props: ["document"],
  data() {
    return {
      default: null,
	  dialogDelete: false,
      componentKey: 0,
    }
  },
  components: {
    VueMomentsAgo,
    ValidatedProgress,
    LabelsDropdown
  },
  computed: {
    ...mapGetters({
      language: "language/getLanguage",
			options: "application/getOptions",
			buttonStyle: "application/getButtonStyle"
    }),
  },
	watch: {
		dialogDelete(val){
			val || this.closeDelete
		},

        //purpose is to force momentsAgo component to refresh
        document:{
            handler(newVal, oldVal){
                this.componentKey++
            },
            deep: true
        }
	},
	methods: {
		...mapActions({
			deleteDocument: "documents/deleteDocument",
		}),
		editDocument() {
			console.log('doc:', this.document.key)
			return router.push({path: '/document/' + this.document.key})
		},
		deleteItem(){
			this.dialogDelete = true
		},
		deleteItemConfirm(){
			this.deleteDoc()
		},
		permanentDeleteItemConfirm() {
			this.permanentlyDeleteDoc()
		},
		closeDelete(){
			this.dialogDelete = false
		},
		permanentlyDeleteDoc() {
			console.log('permanently deleting doc')
			this.$snackbar.showMessage({ content: this.$t("document.msg.queuingPermanentDelete"),
				color: 'success' })
			this.permanentlyDeleteDocument({
				payload: {
					doc_key: this.document.key
				}
			}).then((response) => {
				this.$snackbar.showMessage({ content: this.$t("document.msg.permanentlyDeleted"), 
					color: 'success' })
			}).catch((error) => {
				this.$snackbar.showMessage({ content: this.$t("document.msg.errorPermanentlyDeleted"),
					color: 'error' })
			})
			this.closeDelete()
		},
		deleteDoc() {
			this.deleteDocument({
				payload:{
					doc_key: this.document.key
				}
			}).then( (response) => {
				if (this.options.enable_permanent_delete) {
					this.$snackbar.showMessage({ content: this.$t("document.msg.archived"),
						color: 'success' })
				}
				else {
					this.$snackbar.showMessage({ content: this.$t("document.msg.deleted"),
						color: 'success' })
				}
			}).catch( (error) => {
				this.$snackbar.showMessage({ content: this.$t("document.msg.errorDelete"),
					color: 'error' })
			})
			this.closeDelete()
		},
		zoneTransfer(){
			return router.push({ path: '/settings/zoneTransfer/' + this.document.key })
		}
	},
}
</script>
<style lang="scss" scoped>
.h3-title {
	font-size: 1rem;
}
</style>