<template>
	<div v-if="zones" ref="divZones">
		<!--this is sort of the 'canvas' for svg rectangles/zones-->
		<svg id="svg" ref="SVGZones" version="1.1" xmlns="http://www.w3.org/2000/svg" :width="page.width"
			:height="page.height" style="border: 1px  black" preserveAspectRatio="xMinYMin meet" @mouseup="drop"
			@mousedown="createZone">
			<!--this is for a new zone fill and stroke affect the color of zone as it's being sized-->
			<rect ref="newZoneRect" fill="#0099fe57" stroke="#0099feb0" :width="newZoneRect.w" :height="newZoneRect.h"
				:x="newZoneRect.x" :y="newZoneRect.y" border="1" />

			<!-- this handles the yellow box with zone type & zone order in the upper corners of a zone yellow is the color of the rectangle -->
			<defs>
				<filter x="0" y="0" width="1" height="1" id="solid_green">
					<feFlood flood-color="#00FA8C" />
					<!-- <feComponentTransfer>
          <feFuncA type="linear" slope="0.8"/>
        </feComponentTransfer> -->
					<feComposite in="SourceGraphic" operator="atop" />
				</filter>
			</defs>
			<!-- the zone order box is red ie not-visible zone -->
			<defs>
				<filter x="0" y="0" width="1" height="1" id="solid_red">
					<feFlood flood-color="#fa0a16" />
					<!-- <feComponentTransfer>
          <feFuncA type="linear" slope="0.8"/>
        </feComponentTransfer> -->
					<feComposite in="SourceGraphic" operator="atop" />
				</filter>
			</defs>

			<!-- use for visible=none overlay/fill -->
			<defs>
				<pattern id="not_visible" x="0" y="0" patternUnits="objectBoundingBox" height="100%" width="100%">
					<image x="0" y="0" width="150%" :xlink:href="getHiddenImgUrl" style="opacity: 0.65">
					</image>
				</pattern>
			</defs>

			<!--this is the list of zones
				these values are injected into each instance of SVGZone
				
				@ mousedown="zoneMouseDown" attaches another mousedown listener to the SVGZone.
				the rectangle zone has a mousedown listener which has a handler that emits a mousedown event 
				picket up by zoneMouseDown in this component
				-->
			<SVGZone v-for="(zone, index) in zones" :key="index" :zone="zone" :selected="zone.id == selectedID"
				:focused="zone.id == focusedID" :creating="creatingNewZone" :resizing="resizing" :tabindex="zone.order"
				:initialPosition="initialPosition" :aria-label="zoneAriaLabel(zone)" @mousedown="zoneMouseDown"
				:resizingZone="resizingZone"
				@resizeDown="zoneResizeDown" @resizeRight="zoneResizeRight" @resizeBottom="zoneResizeBottom" 
				v-show="!zone.hideZone && !hideOtherZones && !zone.deleted" />

			<!--this is the list of contrast zones. czIndex is just another alias for the index so we don't reuse the word 'index'-->
			
			<SVGContrastZone v-for="(contrastZone, czIndex) in contrastZones" :key="czIndex + 'c'" :contrastZone="contrastZone" :selected="contrastZone.id == selectedID"
				:tabindex="contrastZone.order"
				:initialPosition="initialPosition" :aria-label="zoneAriaLabel(contrastZone)" @mousedown="zoneMouseDown"
				v-show="!contrastZone.hideZone && !hideContrastZones && showContrastZones"/>
		</svg>

	</div>
	
</template>

<script>
import EventBus from "@/eventBus"
import { mapGetters, mapActions, mapMutations } from "vuex"
import SVGZone from "./SVGZone"
import SVGContrastZone from "./SVGContrastZone.vue"
import stripes from "@/assets/stripes.png"

export default {
	data() {
		return {
			hideOtherZones: false,
			hideContrastZones: false,
			initialPosition: null,
			draggingID: null,
			draggingZone: undefined,
			dragging: false,
			resizingZone: undefined,
			resizing: false,
			resizingRight: false,
			resizingBottom: false,
			newZoneRect: { x: 0, y: 0, w: 0, h: 0 }, // Holds the coordinates for a new Zone
			creatingNewZone: false,
			createInitialPosition: null,
			newX: 0,
			newY: 0,
			newHeight: 0,
			newWidth: 0,
			needForRAF: false
		}
	},
	components: {
		SVGZone, SVGContrastZone,
	},
	computed: {

		selectedID() {
			if (this.selectedZone) {
				return this.selectedZone.id
			}
			return undefined
		},
		focusedID() {
			if (this.focusedZone) {
				return this.focusedZone.id
			}
			return undefined
		},
		getHiddenImgUrl(){
			//return process.env.VUE_APP_HOST_URL + "/img/stripes.png"
			//return require('.assets/stripes.png')
			return stripes
		},
		//hide contrast zones 
		showContrastZones(){
			return false
			/*
			if(process.env.VUE_APP_ENV === 'local' || process.env.VUE_APP_ENV === 'qa'){
				return true
			}
			return false
			*/
		},
		...mapGetters({
			page: "page/getPage",
			zones: "page/getLayout",
			contrastZones: "page/getContrastZones",
			selectedZone: "page/getSelectedZone",
			focusedZone: "page/getFocusedZone",
			zoomLevel: "application/getZoomLevel",
			zoneToggleSetting: "application/getZoneToggleSetting",
			selectedListItem: "page/getSelectedListItem",
			selectedZoneList: "page/getSelectedZoneList",
		}),
	},
	watch: {
		
	},
	methods: {
		...mapMutations({
			setSelectedListItem: "page/SET_SELECTED_LIST_ITEM",
			setSelectedZone: "page/SET_SELECTED_ZONE",
			updateResizedZone: "page/UPDATE_RESIZED_ZONE",
			updateMovedZone: "page/UPDATE_MOVED_ZONE",
			setLayout: "page/SET_LAYOUT",

		}),

		zoneAriaLabel(zone) {
			return zone.type + ' zone ' + zone.order + ' press enter to select'
		},
		getCurrentPosition(event) {
			var x_offset = (this.zoomLevel - 1) * 36 + 320
			var x = event.pageX - x_offset
			var y_offset = (this.zoomLevel - 1) * 25 + 160
			var y = event.pageY - y_offset
			return { x: Math.round(x), y: Math.round(y) }
		},
		getCurrentPositionCreate(event) {
			return { x: Math.round(event.offsetX * this.zoomLevel), y: Math.round(event.offsetY * this.zoomLevel) }
		},

		/*
			On mouse down on zones canvas, create zone is called and a new zone rect is created
			On mouse up, "drop" is called and checks if we're creating a zone.
			The new zone rect is committed via a mutator in the 
		*/
		createZone(evt) {
			evt.preventDefault()
			//console.log('mousedown event in Zones calling createZone')
			this.createInitialPosition = this.getCurrentPositionCreate(evt)

			this.newZoneRect = {
				x: this.createInitialPosition.x / this.zoomLevel,
				y: this.createInitialPosition.y / this.zoomLevel, w: 1, h: 1
			}
			this.creatingNewZone = true

			window.addEventListener("mousemove", this.windowMouseMove)
			window.addEventListener("mouseup", this.drop)
		},

		isContrastZone(zone){
			if(zone.name.includes("Contrast")){
				return true
			}
			return false
		},

		/*
			The zone component ie SVGZone has a listener attached by declarative 
			@mousedown="zoneMouseDown" from this component.  When mousedown happens on rect zone, 
			the event is handled by onMouseDown in zone which then emits an event picked up by this
			method
		*/
		zoneMouseDown({ zone, evt }) {
			evt.preventDefault()
			// console.log('zoneMouseDown event in zones: ', evt)
			this.setSelectedZone(zone)

			//skip the rest if contrast zone
			if(this.isContrastZone(zone)){
				return
			}

			this.initialPosition = this.getCurrentPosition(evt)
			//console.log('updating initial y position to:', this.initialPosition.y)
			//if we click on the resize corner, the resize event fires, then the onMouseDown for the zone itself fires.
			//if we're not already resizing from the onMouseDown resize event, then we're dragging.
			if (!this.resizing) {
				this.draggingZone = zone
				this.draggingID = zone.id
				this.dragging = true
			}

			// this.elementWithFocus = evt.target
			window.addEventListener("mousemove", this.windowMouseMove)
			window.addEventListener("mouseup", this.drop)
		},

		/*
		drop is the event handler for the zones canvas mouseup event.
		IMPORTANT: this is the only mouseup event handler
		*/
		drop() {
			//console.log("mouseup on zones calling drop")
			if (this.creatingNewZone) {
				//need to first check if it's the minimum size, otherwise we'll have micro zones everywhere from random clicking
				if (this.newZoneRect.h > 7 && this.newZoneRect.w > 7) {
					//console.log('in drop. creating zone.')
					this.$store.commit("page/ADD_ZONE", {
						x: Math.round(this.newZoneRect.x),
						y: Math.round(this.newZoneRect.y),
						w: Math.round(this.newZoneRect.w),
						h: Math.round(this.newZoneRect.h)
					})
				}
				//console.log('new zone rect too small for zone creation')
			}

			this.creatingNewZone = false
			this.draggingZone = undefined
			this.draggingID = null
			this.dragging = false //set flag when not dragging
			this.resizingZone = undefined
			this.resizingRight = false
			this.resizingBottom = false
			this.resizing = false
			this.newZoneRect.w = 0
			this.newZoneRect.h = 0
			window.removeEventListener("mousemove", this.windowMouseMove)
			window.removeEventListener("mouseup", this.drop)
		},

		/*
		Zones passes @resizeDown="zoneResizeDown" to zone component. zone resize corner has event listener onResizeDown which
		then emits a resizeDown event that is picked up by this method
		*/
		zoneResizeDown({ zone, evt }) {
			// console.log('resize down event picked up')
			this.initialPosition = this.getCurrentPosition(evt)
			this.draggingZone = undefined
			this.resizingZone = zone
			this.resizing = true

			//testing: added from onMouseDown in eqZone to emit 'resizingZone'
			this.$emit("resizingZone")

			//need to set zone as selected if resize corner clicked
			this.setSelectedZone(zone)
			window.addEventListener("mousemove", this.windowMouseMove)
			window.addEventListener("mouseup", this.drop)
		},
		zoneResizeRight({ zone, evt }) {
			this.resizingRight = true
			this.zoneResizeDown({zone, evt})

		},
		zoneResizeBottom({ zone, evt }) {
			this.resizingBottom = true
			this.zoneResizeDown({zone, evt})
		},
		updateDragPos() {
			if (this.needForRAF && this.draggingZone) {
				this.draggingZone.x += this.newX
				this.draggingZone.y += this.newY
				this.needForRAF = false
				this.updateMovedZone(this.draggingZone)
			}

		},
		updateResizePos() {
			if (this.needForRAF && this.resizingZone) {
				if (!this.resizingBottom) {
					if (this.resizingZone.x + this.resizingZone.w + this.newWidth < this.page.width) {
						this.resizingZone.w = Math.round(this.resizingZone.w + this.newWidth)
					} else {
						this.resizingZone.w = this.page.width - this.resizingZone.x
					}
				}
				if (!this.resizingRight) {
					if (this.resizingZone.y + this.resizingZone.h + this.newHeight < this.page.height) {
						this.resizingZone.h = Math.round(this.resizingZone.h + this.newHeight)
					} else {
						this.resizingZone.h = this.page.height - this.resizingZone.h
					}
				}
				
				//make sure we can't shrink till it disappears
				if(this.resizingZone.h < 7){
					this.resizingZone.h = 7
				}
				if(this.resizingZone.w < 7){
					this.resizingZone.w = 7
				}
				this.needForRAF = false
				this.updateResizedZone(this.resizingZone)
			}
		},
		updateNewZonePos() {
			if (this.needForRAF && this.creatingNewZone) {
				if (Math.round(this.newZoneRect.h + this.newHeight) > 0) {
					this.newZoneRect.h = Math.round(this.newZoneRect.h + this.newHeight)
				}
				if (Math.round(this.newZoneRect.w + this.newWidth) > 0) {
					this.newZoneRect.w = Math.round(this.newZoneRect.w + this.newWidth)
				}
				
				this.needForRAF = false
			}
		},

		/*
		This is the general mousemove handler
		*/
		windowMouseMove(evt) {
			//console.log('mouse is moving!')
			evt.preventDefault()

			//on zoneMouseDown, this.draggingZone is the zone target of the mousedown event
			//if we're mousedown on zone, we may be dragging it.  
			//zoneMouseDown adds mousemove listener which is handled by this method
			if (this.draggingZone !== undefined) {
				if (evt.target.localName === 'rect' || evt.target.localName === 'svg') {
					const currentPosition = this.getCurrentPosition(evt)
					var newX = (currentPosition.x - this.initialPosition.x) / this.zoomLevel
					var newY = (currentPosition.y - this.initialPosition.y) / this.zoomLevel

					if (this.draggingZone.x + newX < 0) {
						newX = 0
					}

					if (this.draggingZone.x + this.draggingZone.w + newX > this.page.width + 100) {
						newX = 0
					}

					if (this.draggingZone.y + newY < 0) {
						newY = 0
					}

					if (this.draggingZone.y + this.draggingZone.h + newY > this.page.height + 100) {
						newY = 0
					}

					// this.elementWithFocus.setAttribute("transform", "translate(" + newX + "," + newY + ")")
					this.newX = Math.round(newX)
					this.newY = Math.round(newY)
					this.initialPosition = currentPosition
					this.needForRAF = true
					requestAnimationFrame(this.updateDragPos)
				}

			}
			//on resizeMouseDown, which comes from mousedown on the resize corner, this.resizingZone is the zone target
			//of the mousedown event. if we're mousedown on the resize corner, we may be resizing the zone.
			//resizeMouseDown adds mousemove listener handled by this method
			else if (this.resizingZone !== undefined) {
				if (evt.target.localName === 'rect' || evt.target.localName === 'svg') {

					const currentPosition = this.getCurrentPosition(evt)
					var newWidth = (currentPosition.x - this.initialPosition.x) / this.zoomLevel
					var newHeight = (currentPosition.y - this.initialPosition.y) / this.zoomLevel

					if (this.resizingRight) {
						this.newWidth = newWidth
					}
					else if (this.resizingBottom) {
						this.newHeight = newHeight
					}
					else {
						this.newWidth = newWidth
						this.newHeight = newHeight

					}
					this.initialPosition = currentPosition
					this.needForRAF = true
					requestAnimationFrame(this.updateResizePos)
				}
			}
			else if (this.creatingNewZone) {
				if (evt.target.localName === 'rect' || evt.target.localName === 'svg') {

					const currentPosition = this.getCurrentPositionCreate(evt)
					// const currentPosition = { x: (evt.target.offsetLeft * this.zoomLevel) + evt.offsetX ,
					//                           y: (evt.target.offsetTop * this.zoomLevel) + evt.offsetY }

					var newZoneWidth = (currentPosition.x - this.createInitialPosition.x) / this.zoomLevel
					var newZoneHeight = (currentPosition.y - this.createInitialPosition.y) / this.zoomLevel

					this.newHeight = newZoneHeight
					this.newWidth = newZoneWidth

					// this.newZoneRect.h += newZoneHeight
					// this.newZoneRect.w += newZoneWidth

					this.createInitialPosition = currentPosition
					this.needForRAF = true
					this.updateNewZonePos()
					// requestAnimationFrame(this.updateNewZonePos)
				}
			}

		},
		toggleShowContrastZones(){
			this.hideContrastZones = !this.hideContrastZones
		},
		toggleShowOtherZones(){
			this.hideOtherZones = !this.hideOtherZones
		},

	},
	mounted() {
		// console.log('Page:', this.page)

		//we may click on a splitter and want to move it, but the initial position depends on clicking a zone.
		//get a message from svgZone to update the initial position
		EventBus.$on("updateInitialPosition", (event) => {
			// console.log('upddateInitialPosition picked up: ', event)
			this.initialPosition = this.getCurrentPosition(event)
		})

		EventBus.$on('SHOW_CONTRAST_ZONES', (msg) => {
			this.toggleShowContrastZones()
		})

		EventBus.$on('SHOW_OTHER_ZONES', (msg) => {
			this.toggleShowOtherZones()
		})
	},
	//kill all listeners on beforeDestroy
	beforeDestroy(){
		EventBus.$off('updateInitialPosition')
		EventBus.$off('SHOW_CONTRAST_ZONES')
		EventBus.$off('SHOW_OTHER_ZONES')
	},
	destroyed(){
		//clear everything out so it's not loaded on next page
		this.setSelectedZone(null)
		this.setSelectedListItem(null)
		//this.setLayout({})
	}
}
</script>
<style scoped>
/* svg {
  position: absolute;
  width: 100%;
  height: 100%;
  font: 5em/1 Arial;
} */
</style>
