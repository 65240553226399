<template>
    <!-- :style="elementStyle" :class="elementClass" -->
    <li v-if="error.data_type === 'pdfout_warning'">{{ error.msg }}</li>
    <li v-else class="error-item">{{ error.msg }}</li>

</template>
<script>
export default {
    name: "OutputErrorItem",
	props: ["error"],
	computed: {
		
	},
}
</script>

<style scoped>

li {
    margin-bottom: 4px;
    padding:2px;
}
.error-item {
	background-color: #FFF6F6;
	color: #9F3A38;
	border: 1px solid #9F3A38;
	border-radius: 4px;
}

</style>
