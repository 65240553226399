
const helpers = {
	findIntersectors(zone, zones) {
		// console.log('in find intersectors')
		// Find zones overlapping the given zone
		let intersectors = []

		if (zones === undefined) {
			return intersectors
		}

		var t_x = [zone.x, zone.x + zone.w]
		var t_y = [zone.y, zone.y + zone.h]
		// console.log('zone is: ', zone)
		// console.log('zones are: ', zones)
		for (var i = 0; i < zones.length; i++) {
			//if (zone.id !== zones[i].id) {
				var i_x = [zones[i].x, zones[i].x + zones[i].w]
				var i_y = [zones[i].y, zones[i].y + zones[i].h]

				if (t_x[0] < i_x[1] && t_x[1] > i_x[0] &&
					t_y[0] < i_y[1] && t_y[1] > i_y[0]) {
					intersectors.push(zones[i])
				}
			//}
		}
		return intersectors
	},

	/*
		Essentially copy of findIntersectors, but does not include the zone itself that we're finding intersectors for.
		findIntersectors has logic to exclude the zone, but is commented out, so it's probably needed somewhere.
	*/
	findIntersectorsExcludingZone(zone, zones) {
		// console.log('in find intersectors')
		// Find zones overlapping the given zone
		let intersectors = []

		if (zones === undefined) {
			return intersectors
		}

		var t_x = [zone.x, zone.x + zone.w]
		var t_y = [zone.y, zone.y + zone.h]
		// console.log('zone is: ', zone)
		// console.log('zones are: ', zones)
		for (var i = 0; i < zones.length; i++) {
			if (zone.id !== zones[i].id) {
				var i_x = [zones[i].x, zones[i].x + zones[i].w]
				var i_y = [zones[i].y, zones[i].y + zones[i].h]

				if (t_x[0] < i_x[1] && t_x[1] > i_x[0] &&
					t_y[0] < i_y[1] && t_y[1] > i_y[0]) {
					intersectors.push(zones[i])
				}
			}
		}
		return intersectors
	},

	generateUUID() {
		var d = new Date().getTime();
		var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
		return uuid;
	},

	isContrastZone(zone) {
		if (zone !== undefined && zone !== null && zone.name.includes("Contrast")) {
			return true
		}
		return false
	},

	/*  TODO: fix this - has jquery


	findIntersectorsByZone(targetSelector, intersectorsSelector) {
		var intersectors = [];

		var t_x = [targetSelector.x, targetSelector.x + targetSelector.w];
		var t_y = [targetSelector.y, targetSelector.y + targetSelector.h];

		for(var i=0; i< intersectorsSelector.length; i++){
			//include 
		}

		$(intersectorsSelector).each(function() {
					var $this = $(this);
					//var thisPos = $this.offset();
					var i_x = [this.offsetLeft, this.offsetLeft + this.offsetWidth]
					var i_y = [this.offsetTop, this.offsetTop + this.offsetHeight];

					if ( t_x[0] < i_x[1] && t_x[1] > i_x[0] &&
							 t_y[0] < i_y[1] && t_y[1] > i_y[0]) {
							intersectors.push(this);
					}

		});
		return intersectors;
	},
	*/

}

export default helpers